import dataHandle from "./_common/dataHandle.js";
import page from "./_common/page.js";
import domHandle from "./_common/domHandle.js";
import routerLoading from "./_common/routerLoading.js";

dataHandle.init();
if (dataHandle.get("pageState") === null) {
    dataHandle.set("pageState", "light");
}
page.data.nowState = dataHandle.get("pageState");
domHandle.init();

export default {
    name: "common",
    data: {
        nowStateChanging: false,
    },
    changeState: function() {
        page.data.nowState = page.data.nowState === "light" ? "dark" : "light";
        dataHandle.set("pageState", page.data.nowState);
        if (this.data.nowStateChanging) {
            return;
        }
        this.data.nowStateChanging = true;
        if (page.data.nowState === "light") {
            page.setPageState("light", 25, 50);
        } else {
            page.setPageState("dark");
        }
        setTimeout(() => {
            this.data.nowStateChanging = false;
        }, 700);
    },
    dataHandle,
    page,
    domHandle,
    routerLoading
}
export default {
    name: "page",
    data: {
        nowState: "light",
        nowStateDoing: false,
        nowBgDeg: 0,
        nowBgColor: [
            [255, 255, 255],
            [255, 255, 255],
        ],
        nowBgBoundary: 0
    },
    setTitle: (title = "loading…") => {
        document.title = title == "loading…" ? "loading…" : `202 - ${title}`;
    },
    setPageState: function (state = "light", deg = 315, boundary = 30, duration = "700") {
        duration = parseInt(duration);
        let startTime;
        document.getElementById("app").nAttr("data-page-state", state);
        document.documentElement.style.setProperty("--commonStateTransition", `${duration / 1000}s`);
        const lightBg = [
            [242, 255, 255],
            [208, 255, 255]
        ]
        const darkBg = [
            [0, 51, 76],
            [0, 0, 0]
        ]
        let changeBg = state === "light" ? lightBg : darkBg;
        let changeChunk = {
            deg: (deg - this.data.nowBgDeg) / duration,
            color: changeBg.map((item, i) => {
                return item.map((childItem, j) => {
                    return (childItem - this.data.nowBgColor[i][j]) / duration;
                });
            }),
            boundary: (boundary - this.data.nowBgBoundary) / duration
        }
        const body = document.getElementsByTagName("body")[0];
        const step = (currentTime) => {
            if (!startTime) startTime = currentTime;
            let timeElapsed = currentTime - startTime;
            let bgColorResult = [
                [],
                []
            ];
            let bgDegResult, bgBoundaryResult;
            let chunk = Math.min(timeElapsed, duration);
            bgDegResult = this.data.nowBgDeg + changeChunk.deg * chunk;
            changeChunk.color.forEach((item, i) => {
                item.forEach((childItem, j) => {
                    bgColorResult[i][j] = this.data.nowBgColor[i][j] + childItem * chunk;
                });
            });
            bgBoundaryResult = this.data.nowBgBoundary + changeChunk.boundary * chunk;
            let bgResult = `linear-gradient(${bgDegResult}deg, rgb(${bgColorResult[0].join(",")}) ${bgBoundaryResult}%, rgb(${bgColorResult[1].join(",")}))`;
            body.style.background = bgResult;
            if (timeElapsed < duration) {
                requestAnimationFrame(step);
            } else {
                this.data.nowBgDeg = bgDegResult;
                this.data.nowBgColor = bgColorResult;
                this.data.nowBgBoundary = bgBoundaryResult;
                startTime = undefined;
            }
        }
        requestAnimationFrame(step);
    }
}
import "../../css/routerLoading.scss";

const routerDom = document.createElement("div");
routerDom.className = "router";

export default {
    name: "routerLoading",
    load({
        top = 0,
        left = 0,
        width = "100%",
        height = "7px"
    }) {
        document.getElementById("body").style.pointerEvents = "none";
        const routerLoadingComponent = `<div class="routerLoadingItem" style="top: ${top}px;left: ${left}px;width: ${width};height: ${height};--size: ${height};"><div class="routerLoadingItemBar"></div></div>`;
        if (!document.querySelector(".router")) {
            document.getElementById("app").appendChild(routerDom);
            routerDom.innerHTML = routerLoadingComponent;
        } else {
            document.querySelector(".router").innerHTML = routerLoadingComponent;
        }
        document.querySelector(".content").nAnim({
            "filter": "blur(5px)"
        }, 700);
    },
    progress(percent = 0, func) {
        const routerLoadingItemBar = document.querySelector(".routerLoadingItemBar");
        routerLoadingItemBar.nAnim({
            "width": `${percent >= 100 ? 100 : percent}%`
        }, {
            duration: percent >= 100 ? 700 : 10000,
            easing: "ease-in-out",
            complete() {
                if (typeof func === "function") {
                    func(routerLoadingItemBar);
                }
            }
        });
    },
    finish(func) {
        this.progress(100, (progressBar) => {
            setTimeout(() => {
                progressBar.style.opacity = 0;
                document.querySelector(".content").nAnim({
                    "filter": "blur(0px)"
                }, 700);
                setTimeout(() => {
                    // progressBar.parentNode.parentNode.remove();
                    document.getElementById("body").style.pointerEvents = "auto";
                    if (typeof func === "function") {
                        func();
                    }
                }, 700);
            }, 700);
        });
    }
}
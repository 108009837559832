export default {
    name: "domHandle",
    init() {
        this.setElementAttributes();
        this.setElementStyle();
        this.setElementAnimate();
    },
    setElementAttributes() {
        Element.prototype.nAttr = function (...arg) {
            let thisElem = this;
            const action = (...arg) => {
                if (arg.length == 0) {
                    throw new Error("parameter can not be empty");
                }
                if (arg.length == 1) {
                    if (arg[0].length == 1) {
                        return thisElem.getAttribute(arg[0]);
                    } else {
                        thisElem.setAttribute(arg[0][0], arg[0][1]);
                    }
                }
            };
            if (typeof arg[0] === "object") {
                for (let key in arg[0]) {
                    action([key, arg[0][key]]);
                }
                return thisElem;
            } else {
                return action(arg);
            }
        }
    },
    setElementStyle() {
        Element.prototype.nStyle = function (...arg) {
            let thisElem = this;
            const action = (...arg) => {
                if (arg.length == 0) {
                    throw new Error("parameter can not be empty");
                }
                if (arg.length == 1) {
                    if (arg[0].length == 1) {
                        return thisElem.style[arg[0][0]];
                    } else {
                        thisElem.style[arg[0][0]] = arg[0][1];
                    }
                }
            }
            if (typeof arg[0] === "object") {
                for (let key in arg[0]) {
                    action([key, arg[0][key]]);
                }
                return thisElem;
            } else {
                return action(arg);
            }
        }
    },
    setElementAnimate() {
        Element.prototype.nAnim = function (styles, ...args) {
            let thisElem = this;
            if (typeof styles !== "object") {
                throw new Error("parameter styles must be an object");
            }
            if (Array.isArray(styles)) {
                if (!styles.every(item => typeof item === "object")) {
                    throw new Error("parameter content in styles must be an array");
                }
            }
            let animateParameter = {
                "fill": "forwards"
            };
            if (!args.every(item => typeof item === "object")) {
                if (args.length == 0) {
                    throw new Error("parameter duration must be a number");
                }
                animateParameter.duration = args[0];
                args[1] !== undefined && (animateParameter.easing = args[1]);
                args[2] !== undefined && (animateParameter.delay = args[2]);
            } else {
                for (let key in args[0]) {
                    animateParameter[key] = args[0][key];
                }
                if (typeof args[0].complete === "function") {
                    setTimeout(args[0].complete(), animateParameter.duration);
                }
            }
            thisElem.animate(styles, animateParameter);
        }
    }
}
export default {
    name: "dataHandle",
    data: {},
    init() {
        this.data = JSON.parse(localStorage.getItem("webAppData")) || {};
    },
    get: function(name) {
        if (!this.data[name]) {
            return null;
        }
        return this.data[name];
    },
    set: function(name, value) {
        this.data[name] = value;
        localStorage.setItem("webAppData", JSON.stringify(this.data));
    },
    remove: function(name) {
        delete this.data[name];
        localStorage.setItem("webAppData", JSON.stringify(this.data));
    }
}
<template>
    <label class="modeChangeButton" ref="modeChangeButtonLabel" skin="light">
        <input type="checkbox" :data-id="id" id="modeChangeButton" ref="modeChangeButton" @click.stop />
        <div class="modeChangeButtonHandler" for="modeChangeButton" :skin="skin">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="modeChangeButtonBg">
            <div class="light">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="dark">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </label>
</template>

<style lang="scss" scoped>
@import "./module.scss";
</style>

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
const props = defineProps({
    id: {
        type: String
    },
    skin: {
        type: String,
        default: "commonMoon"
    },
    continuous: {
        type: Boolean,
        default: true
    },
    state: {
        state: Boolean,
        default: false,
    }
});
const modeChangeButtonLabel = ref();
const modeChangeButton = ref();
watch(props, (newValue) => {
    modeChangeButton.value.checked = newValue.state;
});
var isNextClick = true;
onMounted(() => {
    setTimeout(() => {
        if (props.state) {
            modeChangeButton.value.checked = true;
        } else {
            modeChangeButton.value.checked = false;
        }
        if (props.continuous == "false" || !props.continuous) {
            modeChangeButtonLabel.value.addEventListener("click", (e) => {
                if (!isNextClick) {
                    e.preventDefault();
                    return;
                }
                isNextClick = false;
                setTimeout(() => {
                    isNextClick = true;
                }, 700);
            });
        }
    }, 100);
});
</script>
<template>
  <section id="loading" ref="loadingRef">
    <div class="header" ref="headerRef">
      <div class="cover" ref="coverLogoRef"></div>
      <div class="interval" ref="coverIntervalRef" style="display: none;">×</div>
      <div class="cover" ref="coverOtherRef"></div>
    </div>
  </section>
  <section id="body" ref="bodyRef">
    <div class="header">
      <div class="container">
        <dnSwitch class="dnSwitch fadeIn" continuous="false" @click="changePageMode()" :state="nowState" />
      </div>
    </div>
    <div class="container">
      <div class="nav">
        <router-link v-for="item in routes" :key="item" :to="item.path" ref="routerRef">{{ item.name }}</router-link>
      </div>
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "./assets/css/app.scss";
</style>

<script setup>
import common from "./assets/js/common.js";
import dnSwitch from "./components/dayNightSwitch/App.vue";
import { ref, onMounted } from "vue";

const routes = [
  {
    path: "/",
    name: "首页",
    component: () => import("./page/introduction/App.vue")
  },
  {
    path: "/production",
    name: "作品",
    component: () => import("./page/production/App.vue")
  }
];
routes.forEach(item => {
  window.router.addRoute(item);
});
window.router.addRoute({
  path: "/404",
  name: "404",
  component: () => import("./page/404/App.vue")
});

// import comFooter from "./components/_common/comFooter.vue";

const headerRef = ref();
const coverLogoRef = ref();
const coverOtherRef = ref();
const bodyRef = ref();
const loadingRef = ref();
const routerRef = ref([]);

const nowState = ref(false);
var isNextClick = true;

function changePageMode() {
  if (!isNextClick) {
    return;
  }
  isNextClick = false;
  setTimeout(() => {
    isNextClick = true;
  }, 700);
  common.changeState();
}

onMounted(() => {
  if (common.dataHandle.get("pageState") == "light") {
    common.page.setPageState("light", 25, 50, 5000);
  } else {
    common.page.setPageState("dark", 315, 30, 5000);
    nowState.value = true;
  }
  setTimeout(() => {
    coverOtherRef.value.nAnim(
      {
        "opacity": "0"
      }, {
      duration: 1000,
      complete() {
        headerRef.value.nStyle("bottom", "0px");
        headerRef.value.classList.add("ended");
        setTimeout(() => {
          loadingRef.value.nStyle("pointer-events", "none");
          coverLogoRef.value.nStyle({
            "transition": "all 1s ease-in-out",
            "width": "100%",
            "height": "100%"
          });
          setTimeout(() => {
            coverLogoRef.value.classList.add("ended");
            bodyRef.value.nAnim({
              "opacity": "1"
            }, {
              duration: 300,
              complete() {
                bodyRef.value.classList.add("loaded");
                bodyRef.value.nStyle("pointer-events", "auto");
                let thisRoute = routes.filter(item => item.path == window.location.pathname)[0] ?? "/404";
                window.router.push(thisRoute);
              }
            });
          }, 300);
        }, 1000);
      }
    });
  }, 5700);
});
</script>
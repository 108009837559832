import { createApp } from "vue";
import App from "./App.vue";
// import elementPlus from "element-plus";
// import "element-plus/dist/index.css";
import "./assets/css/common.scss";
import { createRouter, createWebHistory } from "vue-router";
import common from "./assets/js/common";

const routerLoading = common.routerLoading;

const routes = [];

const router = createRouter({
    history: createWebHistory(),
    routes
});
window.router = router;

const routeLoad = () => {
    routerLoading.load({});
    return new Promise(resolve => {
        routerLoading.progress(95);
        setTimeout(() => {
            resolve(true);
            routerLoading.finish();
        }, 1000);
    });
}
router.beforeEach(async (to, from) => {
    console.log(to, from);
    if (to.name === undefined) {
        return false;
    }
    common.page.setTitle();
    await routeLoad();
    common.page.setTitle(to.name);
});

// createApp(App).use(elementPlus).mount("#app")
createApp(App).use(router).mount("#app")